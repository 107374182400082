<template>
  <div class="flex flex-col bg-white h-screen w-full">
    <div class="flex-none h-16">
      <div class="border-2 flex flex-row h-full">
        <div class="flex-none grid place-items-center w-20">
          <app-link :to="{ name: 'dashboard' }" class="text-black">
            <app-icon name="XIcon" class="w-5 cursor-pointer" />
          </app-link>
        </div>
        <div class="flex-1 border-l-2 flex items-center px-10"></div>
      </div>
    </div>
    <div class="flex-1 max-h-full h-0 md:grid grid-cols-10 block">
      <div
        class="
          border-r
          col-span-4
          lg:col-span-3
          overflow-y-scroll
        "
      >
        <list-notification-items />
      </div>
      <div class="col-span-6 lg:col-span-7">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import ListNotificationItems from "./items/list-notification-items.vue";

export default {
  components: {
    ListNotificationItems,
  },
};
</script>
